<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_partner">
      <Sidebar :status="'about'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content">
          <Content></Content>
          <LikeSuggestion></LikeSuggestion>
          <GetStartedContent></GetStartedContent>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import Content from '@/components/about/OurPartnersContent.vue'
import GetStartedContent from '@/components/GetStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'

export default {
  name: 'Partner',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    Content,
    GetStartedContent,
    LikeSuggestion
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Diverse range of innovative products.jpg'),
          title:"Diverse range of innovative <br />products <span class='c-page-banner__regular'>for institutions and<br /> accredited clients</span>",
          buttonText:"Find out more >",
          buttonPage:"/institutional/wealth"
        },
      ],
      theme: 'two',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Strategic Partnerships with Established Digital Assets Entities')
    this.updateDescriptionMeta('Sparrow is proud to partner experienced traditional and cryptocurrency institutions in Singapore, offering the highest levels of operational excellence and assurance.')
    this.updateKeywordMeta('Sparrow, Sparrow Exchange, wealth management companies in Singapore')
  }
}
</script>
