<template>
  <div class="c-page-description">
    <h2>
      Sparrow is backed by
    </h2>
    <h1 class="c-page-description__title c-page-description__title_bold">
      experienced digital assets investors
    </h1>
    <div class="c-page-description__content">
      <p class="c-page-description__content-partner">
        Sparrow’s partnerships with experienced cryptocurrency institutions are a result of our experience and specialism in the digital assets space.
      </p>
    </div>
    <div class="c-page-description__partner">
      <div class="c-page-description__partner-box">
        <div class="c-page-description__partner-box-img" v-for="(dt,index) in partnerList" :key="index" @click="openLink(dt.url)">
          <img class="c-page-description__partner-box-img-detail" :src="dt.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partnerList: []
    }
  },
  mounted() {
    this.getPartnerList()
  },
  methods: {
    getPartnerList() {
      let hardCodePartner = [
        {
          img: require('../../assets/images/partner-signum.svg'),
          url: "http://www.signum.capital"
        },
        {
          img: require('../../assets/images/partner-hyperchain.svg'),
          url: "http://www.hyperchain.capital"
        },
        {
          img: require('../../assets/images/partner-kyber.svg'),
          url: "http://www.kyber.network"
        },
        {
          img: require('../../assets/images/partner-jubilee.svg'),
          url: "http://jcmvc.com/"
        },
        {
          img: require('../../assets/images/partner-lunex.svg'),
          url: "http://lunar.vc/"
        },
        {
          img: require('../../assets/images/partner-100x.svg'),
          url: "https://www.100x.group/"
        },
        {
          img: require('../../assets/images/partner-arrington.svg'),
          url: "http://arringtonxrpcapital.com/"
        },
        {
          img: require('../../assets/images/partner-eos.svg'),
          url: "https://vc.eos.io/"
        },
        {
          img: require('../../assets/images/partner-ducapital.svg'),
          url: "https://www.du.capital/"
        },
        {
          img: require('../../assets/images/partner-cakedefi.svg'),
          url: "https://www.cakedefi.com/"
        },
        // {
        //   img: require('../../assets/images/partner-dch.svg'),
        //   url: "http://www.digitalcurrencyholdings.com/"
        // },
        // {
        //   img: require('../../assets/images/partner-hodlnaut.svg'),
        //   url: "https://www.hodlnaut.com/team"
        // },
        // {
        //   img: require('../../assets/images/partner-digix.svg'),
        //   url: "https://digix.global/"
        // },
        // {
        //   img: require('../../assets/images/partner-qcp.svg'),
        //   url: "https://qcp.capital/"
        // },
        // {
        //   img: require('../../assets/images/partner-vcc.svg'),
        //   url: "https://vcc.exchange/"
        // },
        // {
        //   img: require('../../assets/images/partner-rockminer.svg'),
        //   url: "https://www.rockminer.com/index"
        // },
        // {
        //   img: require('../../assets/images/partner-256venture.svg'),
        //   url: "https://www.256.ventures/"
        // },
        {
          img: require('../../assets/images/partner-block.svg'),
          url: "https://www.block0.ai/"
        },
        {
          img: require('../../assets/images/partner-gleeger.svg'),
          url: "https://www.gleeger.com"
        },
        {
          img: require('../../assets/images/partner-veev.svg'),
          url: "http://veev.tv"
        },
      ]
      this.partnerList = hardCodePartner
    },
  }
}
</script>
